// --------------------------------------------------
// Block Slider002
// --------------------------------------------------

.slider001 {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;

  @media (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .slider001-container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;

    @media (min-width: 576px) {
      max-width: 540px;
    }
    @media (min-width: 768px) {
      max-width: 720px;
    }
    @media (min-width: 992px) {
      max-width: 960px;
    }
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
  }

  .slider001-textbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;

    .slider001-heading {
      @extend .big-title;
      max-width: 1024px;
      margin: 0;
      padding: 0;
      text-align: center;
    }

    .slider001-text {
      @extend .regular-text;
      width: 100%;
      max-width: 775px;
      padding: 0;
      text-align: center;

      @media (max-width: 1070px) {
        width: 60%;
      }

      @media (max-width: 599px) {
        width: 100%;
      }
    }

    &.align-left {
      align-items: flex-start;

      .slider001-text {
        text-align: left;
      }
    }

    &.align-right {
      align-items: flex-end;

      .slider001-text {
        text-align: right;
      }
    }
  }

  .slider001-slider-container {
    position: relative;
    display: flex;
    justify-content: center;

    .slider001-slider {
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;

      @media (min-width: 992px) {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;

        &.is-full {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      .flickity-viewport {
        width: 100%;
      }

      .flickity-prev-next-button {
        width: 50px;
        height: 50px;
        transition: all ease-out .2s;
        cursor: pointer;
        z-index: 1;

        &.previous {
          left: -80px;
        }

        &.next {
          right: -80px;
        }

        &:hover:not(:disabled) {
          transform: translateY(-50%) scale(1.2);
          opacity: 1;
          box-shadow: 0 0 20px 0 rgba($dark, .5);
        }

        &:disabled {
          opacity: .5;
        }
      }

      .flickity-page-dots {
        bottom: -40px;

        .dot {
          background-color: $light;
          transition: background-color ease-out .2s;

          &.is-selected {
            background-color: $colorAccent1;
          }
        }
      }
    }

    .slide-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 1024px) {
        flex-direction: column !important;
      }
    }

    .slide-image-box {
      position: relative;

      .image, img {
        width: 100%;
      }

      .video-overlay {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($dark, .5);

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          color: white;
          font-size: 60px;
          transition: all ease-out .2s;
        }

        &:hover {
          i {
            transform: translate(-50%,-50%) scale(1.2);
            text-shadow: 0 0 20px rgba($dark, .5);
          }
        }
      }

      .video-embed {
        display: none;
      }
    }

    .slide-text-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;

      .slide-heading {
        @extend .medium-title;
        margin-top: 0;
      }

      .slide-text {
        @extend .regular-text;

        p {
          margin: 0;
        }
      }

      .site-btn {
        margin-top: 40px;
        @extend .site-btn;
      }

      &.align-left {
        align-items: flex-start;

        .slide-text {
          text-align: left;
        }
      }

      &.align-right {
        align-items: flex-end;

        .slide-text {
          text-align: right;
        }
      }
    }
  }

  // Options
  &.imageBelowTheText {
    .slider001-slider-container .slide-container {
      flex-direction: column-reverse;

      .slide-text-box {
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
  }

  &.imageOnTheLeft {
    .slider001-slider-container .slide-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .slide-image-box {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;

        @media (min-width: 576px) {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }

      .slide-text-box {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 0;

        @media (min-width: 576px) {
          flex: 0 0 50%;
          max-width: 50%;
        }

        @media (min-width: 992px) {
          flex: 0 0 41.666667%;
          max-width: 41.666667%;
        }
      }
    }
  }

  &.imageOnTheRight {
    .slider001-slider-container .slide-container {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      .slide-image-box {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;

        @media (min-width: 576px) {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }

      .slide-text-box {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 0;

        @media (min-width: 576px) {
          flex: 0 0 50%;
          max-width: 50%;
        }

        @media (min-width: 992px) {
          flex: 0 0 41.666667%;
          max-width: 41.666667%;
        }
      }
    }
  }

  &.grey {
    background-color: $grey;
  }

  &.dark {
    background-color: $dark;

    .slider001-textbox .slider001-heading, .slider001-slider-container .slide-text-box .slide-heading {
      color: white;
    }
  }
}
