// --------------------------------------------------
// Hero
// --------------------------------------------------
.scroll-to-top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 44px;
  height: 44px;
  right: 30px;
  bottom: 30px;
  transform: rotate(-90deg);
  border-radius: 25px;
  z-index: 1000;
  font-size: 10px;

  cursor: pointer;

  background: $colorAccent1;
  color: $white;
}

.hero-home {
  margin: 0 20px;

  @media(max-width: 1024px) {
    margin: 0;
  }

  .hero-banner-wrapper {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media(max-width: 1024px) {
      height: 100%;
      padding-top: 100px;
      padding-bottom: 50px;
    }

    img {
      display: block;
      width: 150px;
      height: 150px;
      margin-bottom: 30px;
    }

    .banner-content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .content-title {
        @extend .big-title;
        font-family: $fontTitle;
        text-align: center;
        margin-bottom: 30px;
        color: $white;
      }
      .content-text {
        @extend .big-text;
        max-width: 350px;
        text-align: center;
        margin-bottom: 30px;
        color: $white;
      }
      .site-btn {

      }
    }
  }
}

.carousel-cell.is-selected {

  img.main {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }

  .carousel-cell-content {
    opacity: 1;
  }
}
.home-section {

}

// --------------------------------------------------
// Introduction
// --------------------------------------------------
.page-introduction {
  display: flex;
  padding: 5px 0 10px 0;

  .intro-content {
    width: 45%;
    padding: 125px 55px;
    padding-right: 175px;

    .regular-text {
      margin-bottom: 75px;
    }
  }

  .intro-image {
    width: 55%;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      display: none;
    }
  }
}

.home-diagram {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

// --------------------------------------------------
// Latest News
// --------------------------------------------------
.latest-news {
  width: 100%;
  padding: 75px 55px;
  background: $alternateWhite;
  text-align: center;

  @media only screen and (max-width: 1349px) {
    padding: 75px 45px;
  }

  @media only screen and (max-width: 1099px) {
    padding: 75px 25px;
  }

  @media only screen and (max-width: 767px) {
    padding: 75px 20px;
  }

  .medium-title {
    display: inline-block;
    margin: 0 auto;
    color: $grey;

    &:before,
    &:after {
      background: $grey;
    }
  }

  .latest-news-grid {
    position: relative;
    display: flex;
  }

  .cards-list-wrapper {
    width: 70%;

    .card-item {
      width: (97% / 2);
    }
  }

  .card-featured {
    position: absolute;
    top: 75px;
    right: 0;
    bottom: 0;

    width: 30%;
    margin: 0.5%;

    box-shadow: 0 0 20px 0 rgba(221, 221, 221, 1);
  }
}

// --------------------------------------------------
// Initiatives
// --------------------------------------------------
.initiatives-list {
  width: 100%;
  padding: 0 55px 75px;
  background: #fff;
  text-align: center;

  @media only screen and (max-width: 1349px) {
    padding: 0 45px 75px;
  }

  @media only screen and (max-width: 1099px) {
    padding: 0 25px 75px;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 20px 25px;
  }

  .medium-title {
    display: inline-block;
    margin: 0 auto;
    color: $grey;

    @media only screen and (max-width: 767px) {
      font-size: 18px;
    }

    &:before,
    &:after {
      background: $grey;
    }
  }
}

// --------------------------------------------------
// Newsletter
// --------------------------------------------------
.newsletter-cta {
  display: flex;
  padding: 5px 0 10px 0;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }

  .newsletter-content {
    position: relative;
    width: 60%;
    padding: 75px 55px;
    padding-right: 175px;
    color: #fff;

    background: $colorAccent1;
    background-image: url('/img/site/img-ph1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;

    @media only screen and (max-width: 1349px) {
      padding: 75px 45px;
    }

    @media only screen and (max-width: 1099px) {
      padding: 75px 25px;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 35px 20px;
      text-align: center;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $colorAccent1;
      opacity: .60;

      z-index: 1;
    }

    .big-title {
      // max-width: 300px;
      line-height: 1.3;
      font-size: 26px;
      margin-bottom: 25px;

      @media only screen and (max-width: 767px) {
        font-size: 24px;
      }
    }

    .regular-text {
      margin-bottom: 35px;
      max-width: 400px;

      @media only screen and (max-width: 767px) {
        max-width: inherit;
      }
    }

    .big-title,
    .link-text-btn,
    .regular-text {
      position: relative;
      color: #fff;
      border-color: #fff;
      z-index: 1;
    }

    .site-btn {
      position: relative;
      color: #fff;
      border-color: #fff;

      z-index: 2;

      &:hover {
        background: #fff;
        color: $colorAccent1;
      }
    }
  }

  .newsletter-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    width: calc(100% - 60%);
    padding: 65px 55px;
    background: $alternateWhite;
    text-align: left;

    @media only screen and (max-width: 1349px) {
      padding: 65px 45px;
    }

    @media only screen and (max-width: 1099px) {
      padding: 65px 25px;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 35px 20px;
      text-align: center;

      .newsletter-component {
        width: 100%;
      }
    }

    .big-title {
      font-family: $fontTextLight;
      width: 100%;
      font-weight: 400;
      letter-spacing: 0;
      font-size: 32px;
      line-height: 1.3;

      @media only screen and (max-width: 767px) {
        font-size: 26px;
        padding: 0;
        margin: 0 0 25px 0;
      }

      img {
        width: 35px;
      }
    }

    .newsletter-form {
      display: flex;
      width: 100%;

      input {
        width: 100%;
        background: #fff;
        // border: 2px solid $colorAccent1;
        border: none;
        color: $colorAccent1;
        // border-radius: 3px;
        padding: 15px 20px;
        outline: none;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.02em;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        box-shadow: 0 0 35px 0 rgba(221, 221, 221, .8);


      }

      button {
        padding: 15px 35px;
        // border-radius: 3px;
        background: $colorAccent1;
        letter-spacing: 0.055em;
        font-family: $fontTitleSerif;
        font-size: 16px;
        color: #fff;
        border: 1px solid $colorAccent1;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        cursor: pointer;

        transition: all 0.35s ease;

        &:hover {
          background: $colorAccent3;
          color: #fff;
          border-color: $colorAccent3;
        }
      }
    }

    .hbspt-form form {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .hs-button,
      .hs-button.primary.large {
        position: relative;
        top: 2px;
        left: -3px;
        padding: 15px 35px;
        margin: 0;
        // border-radius: 3px;
        background: $colorAccent1;
        letter-spacing: 0.055em;
        font-family: $fontTitleSerif;
        font-size: 16px;
        color: #fff;
        border: 1px solid $colorAccent1;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        cursor: pointer;

        transition: all 0.35s ease;

        &:hover {
          background: $colorAccent3;
          color: #fff;
          border-color: $colorAccent3;
        }
      }

      .hs_error_rollup {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translate3d( 0, 100%, 0);
      }

      .hs-error-msgs.inputs-list {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translate3d( 0, 100%, 0);
        line-height: 1;
      }
    }
  }
}
