// --------------------------------------------------
// Intro 001
// --------------------------------------------------

.block-intro001 {
  background-color: transparent;
  max-width: 100%;
  padding-bottom: 0;

  &.grey {
    // background-color: $grey;
  }

  &.dark {
    // background-color: $dark;

    .intro-title {
      color: white;
    }
  }

  .block-wrapper {
    @extend .site-max-width;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.left {
      align-items: flex-start;

      .intro-content {
        text-align: left;
        margin: 0;
      }
    }

    &.right {
      align-items: flex-end;

      .intro-content {
        text-align: right;
        margin: 0;
      }
    }
  }
  .intro-logo {
    display: block;
    margin-bottom: 50px;
  }

  .intro-step {
    @extend .regular-text;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: $dark;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .intro-title {
    @extend .big-title;
    color: $colorAccent1;
    max-width: 1024px;
    margin: 0;
    padding: 0;
    text-align: center;
    margin-bottom: 50px;
  }

  .intro-separator {
    display: none;
    height: 4px;
    width: 135px;
    margin: 35px 0;
    padding: 0;
    background-color: $colorAccent1;

    @media (max-width: 979px) {
      margin: 25px 0;
    }
  }

  .intro-content {
    @extend .regular-text;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    padding: 0;
    text-align: center;

    @media (max-width: 1070px) {
      width: 60%;
    }

    @media (max-width: 599px) {
      width: 100%;
    }

    p {
      margin: 0;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      color: $dark;

      &:first-child {
        margin-bottom: 30px;
      }

      a {
        color: $colorAccent1;
        border-bottom: 1px solid $colorAccent1;
        cursor: pointer;

        &:after {
          content: "\E82B";
          font-family: fontello;
          font-size: 16px;
          // color: $colorAccent1;
          margin-left: 10px;
        }

        &:hover {
          color: #870F00;
          border-bottom: 1px solid #870F00;
        }
      }

      em {
        display: block;
        font-size: 14px;
        margin-top: 30px;
      }
    }

    ul,
    ul li {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  }

  .site-btn {
    margin-top: 40px;
  }
}
