// --------------------------------------------------
// Footer
// --------------------------------------------------

.main-footer {
  position: relative;
  background: $colorAccent1;
  color: #fff;
  font-family: $fontTextLight;

  z-index: 1;

  @media only screen and (max-width: 1639px) {
    background-position: right -5% top;
  }

  @media only screen and (max-width: 1514px) {
    background: $colorAccent1;
    background-image: none;
  }

  .socials {
    position: absolute;
    top: 40px;
    right: 0;
    font-size: 20px;

    a {
      margin-right: 0;

      @media only screen and (max-width: 1349px) {
        font-size: 20px;
      }
    }
  }

  .logo {
    position: absolute;
    bottom: 0;
    left: 75px;
    transform: translate3d(0, 5px, 0);
    padding: 0;
    margin-right: auto;

    @media only screen and (max-width: 1349px) {
      left: 45px;
    }

    @media only screen and (max-width: 1099px) {
      left: 25px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
      position: relative;
      left: 0;
      margin: 0 auto;
    }

    img {
      width: 245px;
    }
  }
}

.footer-nav {
  @extend .site-max-width;

  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 75px;
  padding-bottom: 20px;
  font-size: 16px;

  @media only screen and (max-width: 1349px) {
    // padding: 45px 0 25px 0;
    padding-top: 45px;
    padding-bottom: 25px;
  }

  @media only screen and (max-width: 767px) {
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  a {
    display: inline-block;
    margin-right: 75px;
    font-size: 14px;

    transition: all 0.35s ease;

    &:hover {
      color: $colorAccent3;
    }

    @media only screen and (max-width: 1349px) {
      margin-right: 25px;
      font-size: 14px;
    }

    @media only screen and (max-width: 767px) {
      margin-right: 15px;
      margin-left: 15px;
      line-height: 1.5;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.footer-copy {
  @extend .site-max-width;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 55px;
  line-height: 1;
  // border-top: 1px solid rgba(#fff, 0.10);
  font-size: 12px;

  @media only screen and (max-width: 1199px) {
    padding-bottom: 25px;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    text-align: center;
    line-height: 1.3;
  }

  a {
    display: inline-block;
    padding-right: 45px;
    font-size: 14px;

    @media only screen and (max-width: 1349px) {
      padding-right: 25px;
    }

    @media only screen and (max-width: 899px) {
      display: none;

      &:nth-child(4),
      &:nth-child(5) {
        display: inline-block;
      }
    }



    &:last-child {
      padding-right: 0;
    }
  }
}


//Footer
.site-footer {
  // background-color: $dark;
  padding: 60px 0 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 550px) {
    padding: 40px 20px 0;
  }

  .footer-container {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto 65px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1000px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  .footer-logo {
    display: block;
    width: 150px;
    height: 150px;
    margin: 0 auto;

    @media(max-width: 768px) {
      width: 50px;
      height: 50px;
      margin-bottom: 20px;
    }
  }

  .footer-column {
    width: calc(25% - ((60px * 3) / 4));
    margin: 0 auto;

    @media (max-width: 1000px) {
      width: 100%;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .nav-link {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      width: 40px;
      height: 40px;
      border-radius: 25px;
      color: $colorAccent1;
    }
  }

  .footer-title {
    font-family: $fontTitle;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: .5px;
    font-weight: 600;
    color: #929292;
    text-transform: uppercase;
    padding: 0 0 10px;
    border-bottom: 1px solid #525252;
    margin: 0 0 10px;
  }

  .footer-text {
    font-family: $fontTitle;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: .5px;
    color: white;
    margin: 0 0 20px;
  }

  .footer-newsletter-form {
    display: flex;
    margin-bottom: 20px;

    input[type=email] {
      flex-grow: 1;
      padding: 10px 15px;
      border: none;
      outline: 0;
      line-height: 24px;
    }

    button[type=submit] {
      padding: 10px 15px;
      border: none;
      outline: 0;
      font-weight: 700;
      color: white;
      line-height: 24px;
      background-color: $colorAccent1;
    }
  }

  .nav-link {
    display: block;
    font-family: $fontTitle;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: .5px;
    font-weight: 500;
    color: white;
    padding: 12px 0;
    transition: color ease-out .2s;

    i {
      margin-right: 12px;
      font-size: 16px;
    }

    &:hover {
      color: $colorAccent1;
    }
  }

  .footer-bottom {
    display: flex;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: .5px;
    color: rgba(white, .75);
    font-weight: 500;
    min-width: 100%;

    img {
      display: block;
      width: 100%;
    }

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .footer-legal {
    margin: 0 10px 0 0;
    border-right: 1px solid white;
    padding-right: 10px;

    @media (max-width: 1023px) {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }
  }

  .footer-rights {
    color: rgba(white, .75);
    font-weight: 500;
    transition: color ease-out .2s;
    border-right: 1px solid white;
    padding-right: 10px;
    margin-right: 10px;

    &:last-child {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }

    @media (max-width: 1023px) {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }

    &:hover {
      color: $colorAccent1;
    }
  }
}
