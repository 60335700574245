// --------------------------------------------------
// Block Split001
// --------------------------------------------------

.split001 {
  padding: 45px 0;
  margin-bottom: 200px;

  @media(max-width: 768px) {
    margin-bottom: 100px;
  }

  .split001-wrapper {
    @extend .site-max-width;

    &:nth-child(even) {
      .split001-wrapper-container {
        flex-direction: row-reverse;
      }
    }
  }

  .split001-wrapper-container {
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    // Row Alignment
    &.imageLeft {
      flex-direction: row !important;
    }

    &.imageRight {
      flex-direction: row-reverse !important;
    }

    // Text Alignment
    &.centered {
      text-align: center !important;
    }

    &.left {
      text-align: left !important;
    }

    &.right {
      text-align: right !important;
    }

    // Theme
    &.light {
      background-color: white;

      .split001-text-wrapper .split001-content-container .split001-heading {
        color: $dark;
      }
    }

    &.grey {
      background-color: $grey;

      .split001-text-wrapper .split001-content-container .split001-heading {
        color: $dark;
      }
    }

    &.dark {
      background-color: $dark;

      .split001-text-wrapper .split001-content-container .split001-heading {
        color: white;
      }
    }
  }

  .split001-text-wrapper {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $grey;

    @media(max-width: 1024px) {
      width: 100%;
    }

    .split001-content-container {
      width: 80%;
      margin: 0 auto;

      @media(max-width: 1024px) {
        padding: 60px 0;
      }

      .split001-number {
        font-weight: bold;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $dark;
        opacity: 0.5;

        margin: 0;
      }

      .split001-heading {
        @extend .medium-title;
        margin-top: 0;
        margin-bottom: 10px;
        color: $colorAccent1;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 53px;

        @media(max-width: 768px) {
          font-size: 26px;
          line-height: 1.2;
        }
      }

      .split001-text {
        @extend .regular-text;
        margin-bottom: 30px;

        p {
          margin: 0;
          color: $dark;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 250%;

          em {
            display: block;
            margin-bottom: 50px;
            font-weight: normal;
            font-size: 18px;
            line-height: 150%;
            color: $dark;
          }
        }
      }

      .site-btn {
        @extend .site-btn;
      }
    }
  }

  .split001-image-wrapper {
    position: relative;
    width: 50%;

    @media(max-width: 1024px) {
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
    }

    .video-overlay {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($dark, .5);

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: white;
        font-size: 60px;
        transition: all ease-out .2s;
      }

      &:hover {
        i {
          transform: translate(-50%,-50%) scale(1.2);
          text-shadow: 0 0 20px rgba($dark, .5);
        }
      }
    }

    .video-embed {
      display: none;
    }

    .gallery-slides {
      .gallery-slide {
        width: 100%;

        .image {
          width: 100%;
        }
      }

      .flickity-prev-next-button {
        width: 50px;
        height: 50px;
        opacity: .75;
        transition: all ease-out .2s;

        &.previous {
          left: 20px;
        }

        &.next {
          right: 20px;
        }

        &:hover {
          transform: translateY(-50%) scale(1.2);
          opacity: 1;
          box-shadow: 0 0 20px 0 rgba($dark, .5);
        }
      }
    }
  }

  // Options
  &.full-width {
    padding: 0;
    margin: 45px 0;

    .split001-wrapper {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.space-between {
    .split001-wrapper:not(:first-child) {
      margin-top: 100px;
    }
  }

  // Text Alignment
  &.closeToThePicture {
    .split001-wrapper {
      .split001-wrapper-container {
        text-align: left;
      }

      // &:nth-child(even) {
      //   .split001-wrapper-container {
      //     text-align: right;
      //   }
      // }
    }
  }

  &.awayFormThePicture {
    .split001-wrapper {
      .split001-wrapper-container {
        text-align: right;
      }

      &:nth-child(even) {
        .split001-wrapper-container {
          text-align: left;
        }
      }
    }
  }

  // Row Alignment
  &.imageRight {
    .split001-wrapper {
      .split001-wrapper-container {
        flex-direction: row-reverse;
      }

      &:nth-child(even) {
        .split001-wrapper-container {
          flex-direction: row;
        }
      }
    }


    // Text Alignment
    &.closeToThePicture {
      .split001-wrapper {
        .split001-wrapper-container {
          text-align: right;
        }

        &:nth-child(even) {
          .split001-wrapper-container {
            text-align: left;
          }
        }
      }
    }

    &.awayFormThePicture {
      .split001-wrapper {
        .split001-wrapper-container {
          text-align: left;
        }

        &:nth-child(even) {
          .split001-wrapper-container {
            text-align: right;
          }
        }
      }
    }
  }

  // Theme
  &.grey {
    background-color: $grey;
  }

  &.dark {
    background-color: $dark;

    .split001-text-wrapper .split001-content-container {
      .split001-heading {
        color: white;
      }
    }
  }
}
