// --------------------------------------------------
// Header
// --------------------------------------------------

.main-header {
  // display: none;

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 40px 80px;
  z-index: 10;
  background: transparent;

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transition: -webkit-transform 0.7s .3s ease;
    transition: transform 0.7s .3s ease;
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    -webkit-backface-visibility: hidden;
    // box-shadow: 0 1px 5px 0px rgba(0, 0, 0, .15);

    &.hide-nav-bar {
        -webkit-transform: translate(0, -100%);
        -ms-transform: translate(0, -100%);
        transform: translate(0, -100%);
        -webkit-backface-visibility: hidden;
    }
  }

  @media (max-width: 1400px) {
    padding: 40px;
  }

  @media (max-width: 1299px) {
    padding: 30px;
  }

  @media (max-width: 1100px) {
    padding: 20px;
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .footer-column {
      .nav-link {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        width: 40px;
        height: 40px;
        border-radius: 25px;
        color: $colorAccent1;
        margin-bottom: 10px;
      }
    }
  }

  .header-logo {
    img {
      height: 40px;

      @media (max-width: 1024px) {
        height: 20px;
      }
    }
  }

  .header-nav {
    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
      display: none;
    }

    .header-link {
      position: relative;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 1px;
      font-weight: 500;
      color: #fff;
      text-transform: uppercase;
      margin: 0 20px;

      transition: all 0.35s ease;

      &:hover {
        color: $colorAccent1;
      }

      @media (max-width: 1400px) {
        font-size: 14px;
        margin: 0 10px;
      }

      &.is-active:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: white;
      }
    }
  }

  .header-right {
    display: flex;
    align-self: flex-start;

    .header-phone {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 1px;
      font-weight: 500;
      color: #fff;
      text-transform: uppercase;
      margin: 0 20px 0 0;

      a {
        transition: all 0.35s ease;

        &:first-child {
          margin-right: 20px;

          @media (max-width: 1024px) {
            margin-right: 5px;
          }
        }

        span {

          @media(max-width: 768px) {
            display: none;
          }

        }

        i {
          display: none;

          @media(max-width: 768px) {
            display: block;
          }
        }


        // &:hover {
        //   color: $colorAccent1;
        // }
      }

      @media (max-width: 1400px) {
        font-size: 14px;
      }

    }

    .site-btn {
      @media (max-width: 1400px) {
        padding: 10px 20px;
        font-size: 12px;
      }

      @media (max-width: 1024px) {
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }

    .mobile-menu-trigger {
      display: none;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background: url('/img/site/icon-burger.svg') no-repeat center center / contain;

      @media (max-width: 1024px) {
        display: block;
      }
    }
  }
}
